import { AxiosError } from "axios";

export const parseBasePydanticErrorMessage = (errors: any[]) => {
  return errors.map((err: any) => (err && err.loc && err.msg ? `${err.loc[1]} : ${err.msg}` : "")).join("  ---  ");
};

export const getDataproviderPydanticErrorMessage = (error: any) => {
  if (error && error.body && error.body.detail) {
    return parseBasePydanticErrorMessage(error.body.detail);
  } else {
    return "An error occurred. Please try again.";
  }
};

type CustomErrorObjectType = {
  message: string;
  error_code: string;
};

const getCustomErrorMessages = (errors: CustomErrorObjectType[]) => {
  return errors.map((err: CustomErrorObjectType) => err.message).join("  ---  ");
};

export const getDataproviderCustomErrorMessage = (error: any) => {
  if (error && error.body) {
    return getCustomErrorMessages(error.body as Array<CustomErrorObjectType>);
  } else {
    return "An error occurred. Please try again.";
  }
};

export const getAxiosCustomErrorMessage = (error: AxiosError) => {
  if (error.response && error.response.data) {
    const messages = getCustomErrorMessages(error.response.data as Array<CustomErrorObjectType>);
    return messages;
  } else {
    return "An error occurred. Please try again.";
  }
};
