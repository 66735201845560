import { baseResources } from "config_infos";
import {
  AutocompleteInput,
  Create,
  DateTimeInput,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
} from "react-admin";
import { optimizerCertificationsTestsTypeChoices } from "../opti_enums";

export default function CertificationsTestsCreate() {
  return (
    <Create>
      <SimpleForm>
        <DateTimeInput source="start_date" label="Start Date" fullWidth validate={required()} />
        <DateTimeInput source="end_date" label="End Date" fullWidth validate={required()} />
        <NumberInput source="fcr_engagement" label="FCR engagement" fullWidth validate={required()} />
        <NumberInput source="afrr_engagement" label="AFRRR engagement" fullWidth validate={required()} />
        <ReferenceInput source="certif_id" reference={baseResources.optimizer.CERTIFICATIONS}>
          <SelectInput optionText="certif_name" fullWidth validate={required()} label="Certification" />
        </ReferenceInput>
        <SelectInput
          source="test_type"
          label="Test Type"
          choices={optimizerCertificationsTestsTypeChoices}
          fullWidth
          validate={required()}
        />
        <ReferenceInput
          source="group_code"
          reference={baseResources.sites.GROUPS}
          fullWidth
          label={"Code EDP (rte_edp code)"}
        >
          <AutocompleteInput
            optionText={({ id, name, code }: any) => {
              return `${id} - ${name} - ${code}`;
            }}
            fullWidth
            label={"Code EDP (rte_edp code)"}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
