import { baseResources } from "config_infos";
import { enumToChoices } from "helpers/reactAdminHelpers";
import { useCallback, useMemo } from "react";
import { AutocompleteInput, Create, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from "react-admin";
import { OptimizerCertificationStatusEnum, OptimizerGroupTypeEnum } from "../opti_enums";

export default function GroupsCertificationsCreate() {
  const statusOptions = useMemo(() => enumToChoices(OptimizerCertificationStatusEnum), []);

  const transform = useCallback((data: any) => {
    return {
      ...data,
      groupe_type: OptimizerGroupTypeEnum.RTE_EDP,
    };
  }, []);

  return (
    <Create transform={transform}>
      <SimpleForm>
        <TextInput source="name" label="Group Certification Name" validate={[required()]} fullWidth />
        <SelectInput source="status" choices={statusOptions} validate={[required()]} fullWidth />

        <ReferenceInput
          source="group_code"
          reference={baseResources.sites.GROUPS}
          filter={{
            type: OptimizerGroupTypeEnum.RTE_EDP,
          }}
        >
          <AutocompleteInput
            optionText={({ id, name, code }: any) => {
              return `${id} - ${name} - ${code}`;
            }}
            fullWidth
            validate={[required()]}
          />
        </ReferenceInput>
        <ReferenceInput source="certification_id" reference={baseResources.optimizer.CERTIFICATIONS}>
          <AutocompleteInput
            optionText={({ id, certif_name }: any) => {
              return `${id} - ${certif_name}`;
            }}
            validate={[required()]}
            fullWidth
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
